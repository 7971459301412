@charset "UTF-8";
/*
 * Copyright 2015 Palantir Technologies, Inc. All rights reserved.
 * Licensed under the BSD-3 License as modified (the “License”); you may obtain a copy
 * of the license at https://github.com/palantir/blueprint/blob/master/LICENSE
 * and https://github.com/palantir/blueprint/blob/master/PATENTS
 */
/*
Date & time

<div class="pt-callout pt-intent-primary pt-icon-info-sign">
  <h5>Separate package required</h5>
  The date and time components do not ship with the `@blueprintjs/core` package,
  and must be installed separately as `@blueprintjs/datetime`.
</div>

This package provides several components for interacting with dates and times:

- [`DatePicker`](#components.datetime.datepicker) for selecting a single date (day, month, year).

- [`DateRangePicker`](#components.datetime.daterangepicker) for selecting date ranges.

- [`TimePicker`](#components.datetime.timepicker) for selecting a time (hour, minute, second,
  millisecond).

- [`DateTimePicker`](#components.datetime.datetimepicker), which composes `DatePicker` and
  `TimePicker` to select a date and time together.

- [`DateInput`](#components.datetime.dateinput), which composes a text input with a `DatePicker` in
  a `Popover`, for use in forms.

They are available in the __@blueprintjs/datetime__ package on
[NPM](https://www.npmjs.com/package/@blueprintjs/datetime).

Make sure to review the [general usage docs for JS components](#components.usage).

```sh
npm install --save @blueprintjs/datetime
```

Styleguide components.datetime
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
A mixin to generate the classes for a React CSSTransitionGroup which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For leave transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("pt-popover", (opacity: 0 1), $before: "&");`
Transition named "pt-popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransitionGroup.
`$phase` must be `appear` or `enter` or `leave`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `leave` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(pt-toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(pt-toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-datepicker {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  padding: 10px;
  user-select: none; }
  .pt-datepicker .DayPicker {
    display: inline-block;
    position: relative;
    min-width: 210px;
    vertical-align: top; }
    .pt-datepicker .DayPicker:focus {
      outline: none; }
  .pt-datepicker .DayPicker-Month {
    display: inline-table;
    margin: 0 auto;
    padding: 0 5px;
    border-collapse: collapse;
    border-spacing: 0;
    user-select: none; }
    .pt-datepicker .DayPicker-Month + .pt-datepicker .DayPicker-Month {
      margin-left: 10px; }
  .pt-datepicker .DayPicker-NavBar {
    position: relative; }
  .pt-datepicker .DayPicker-NavButton {
    color: #5c7080;
    position: absolute;
    top: -5px;
    cursor: pointer;
    padding: 7px; }
    .pt-datepicker .DayPicker-NavButton:hover {
      color: #182026; }
    .pt-dark .pt-datepicker .DayPicker-NavButton {
      color: #bfccd6; }
      .pt-dark .pt-datepicker .DayPicker-NavButton:hover {
        color: #f5f8fa; }
    .pt-datepicker .DayPicker-NavButton--prev {
      left: -5px; }
      .pt-datepicker .DayPicker-NavButton--prev::before {
        line-height: 1;
        font-family: "Icons16", sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: ""; }
    .pt-datepicker .DayPicker-NavButton--next {
      right: -5px; }
      .pt-datepicker .DayPicker-NavButton--next::before {
        line-height: 1;
        font-family: "Icons16", sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        content: ""; }
  .pt-datepicker .DayPicker-Weekdays {
    display: table-header-group; }
  .pt-datepicker .DayPicker-WeekdaysRow {
    display: table-row; }
  .pt-datepicker .DayPicker-Weekday {
    display: table-cell;
    width: 30px;
    height: 30px;
    padding-top: 5px;
    vertical-align: middle;
    text-align: center;
    line-height: 1;
    font-weight: 600; }
    .pt-datepicker .DayPicker-Weekday abbr[title] {
      text-decoration: none; }
  .pt-datepicker .DayPicker-Body {
    display: table-row-group; }
  .pt-datepicker .DayPicker-Week {
    display: table-row; }
  .pt-datepicker .DayPicker-Day {
    display: table-cell;
    border-radius: 3px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    line-height: 1; }
    .pt-datepicker .DayPicker-Day.DayPicker-Day--outside {
      color: rgba(92, 112, 128, 0.5); }
    .pt-datepicker .DayPicker-Day:hover, .pt-datepicker .DayPicker-Day:focus {
      background: #d8e1e8;
      color: #182026; }
    .pt-datepicker .DayPicker-Day:active {
      background: #ced9e0; }
    .pt-datepicker .DayPicker-Day.DayPicker-Day--selected {
      border-radius: 3px;
      background-color: #137cbd;
      color: #ffffff; }
      .pt-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
        background-color: #106ba3; }
    .pt-datepicker .DayPicker-Day.DayPicker-Day--disabled {
      background: none;
      cursor: not-allowed;
      color: rgba(92, 112, 128, 0.5); }
  .pt-datepicker .pt-datepicker-caption {
    display: table-caption;
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    text-align: center; }
    .pt-datepicker .pt-datepicker-caption select {
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: -5px 0 5px;
      border: 0;
      background: none;
      cursor: pointer;
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
      color: #182026;
      font-weight: 600; }
      .pt-datepicker .pt-datepicker-caption select:focus + .pt-datepicker-caption-caret {
        display: inline; }
      .pt-datepicker .pt-datepicker-caption select::-ms-expand {
        display: none; }
  .pt-datepicker .pt-datepicker-caption-select {
    display: inline-block;
    position: relative; }
  .pt-datepicker .pt-datepicker-caption-caret {
    display: none;
    position: absolute;
    top: 2px;
    margin-left: 10px;
    pointer-events: none; }
  .pt-datepicker .pt-datepicker-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: -5px;
    border-top: 1px solid rgba(16, 22, 26, 0.15);
    padding-top: 5px; }
  .pt-datepicker .DayPicker-Month:hover .pt-datepicker-caption-caret {
    display: inline; }

.pt-datepicker-caption-measure {
  font-weight: 600; }

.pt-dark .pt-datepicker {
  background: #30404d; }
  .pt-dark .pt-datepicker .DayPicker-Day.DayPicker-Day--outside {
    color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-datepicker .DayPicker-Day:hover, .pt-dark .pt-datepicker .DayPicker-Day:focus {
    background: #5c7080;
    color: #ffffff; }
  .pt-dark .pt-datepicker .DayPicker-Day:active {
    background: #738694; }
  .pt-dark .pt-datepicker .DayPicker-Day.DayPicker-Day--selected {
    background-color: #137cbd; }
    .pt-dark .pt-datepicker .DayPicker-Day.DayPicker-Day--selected:hover {
      background-color: #2b95d6; }
  .pt-dark .pt-datepicker .DayPicker-Day.DayPicker-Day--disabled {
    background: none;
    color: rgba(191, 204, 214, 0.5); }
  .pt-dark .pt-datepicker .pt-datepicker-caption {
    border-bottom-color: rgba(16, 22, 26, 0.4); }
    .pt-dark .pt-datepicker .pt-datepicker-caption select {
      color: #f5f8fa; }
    .pt-dark .pt-datepicker .pt-datepicker-caption option {
      background-color: #30404d; }
  .pt-dark .pt-datepicker .pt-datepicker-footer {
    border-top-color: rgba(16, 22, 26, 0.4); }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-daterangepicker {
  display: flex;
  white-space: nowrap; }
  .pt-daterangepicker .DayPicker:not(:last-child) {
    margin-right: 10px; }
  .pt-daterangepicker .pt-daterangepicker-shortcuts + .DayPicker {
    border-left: 1px solid rgba(16, 22, 26, 0.15);
    min-width: 220px;
    padding-left: 10px; }
  .pt-daterangepicker.pt-daterangepicker-contiguous .DayPicker {
    min-width: 430px; }
  .pt-daterangepicker.pt-daterangepicker-contiguous .pt-daterangepicker-shortcuts + .DayPicker {
    min-width: 440px; }
  .pt-daterangepicker.pt-daterangepicker-contiguous .DayPicker-Month:not(:last-child) {
    margin-right: 10px; }
  .pt-daterangepicker.pt-daterangepicker-single-month .DayPicker {
    min-width: 210px; }
  .pt-daterangepicker.pt-daterangepicker-single-month .pt-daterangepicker-shortcuts + .DayPicker {
    min-width: 220px; }
  .pt-daterangepicker .DayPicker-Day--outside {
    visibility: hidden; }
  .pt-daterangepicker .DayPicker-Day--hovered-range {
    border-radius: 0; }
    .pt-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
      background-color: #ebf1f5; }
  .pt-daterangepicker .DayPicker-Day--selected-range {
    border-radius: 0;
    background-color: #ebf1f5; }
    .pt-daterangepicker .DayPicker-Day--selected-range:hover {
      background-color: #ced9e0; }
  .pt-daterangepicker .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .pt-daterangepicker .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .pt-daterangepicker .DayPicker-Day--hovered-range-start:not(.DayPicker-Day--hovered-range-end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .pt-daterangepicker .DayPicker-Day--hovered-range-end:not(.DayPicker-Day--hovered-range-start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .pt-dark .pt-daterangepicker .DayPicker-Day--hovered-range:not(.DayPicker-Day--selected):not(.DayPicker-Day--selected-range):not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
    background-color: #394b59; }
  .pt-dark .pt-daterangepicker .DayPicker-Day--selected-range {
    background-color: #394b59; }
    .pt-dark .pt-daterangepicker .DayPicker-Day--selected-range:hover {
      background-color: #5c7080; }

.pt-menu.pt-daterangepicker-shortcuts {
  display: inline-block;
  margin-top: -5px;
  margin-left: -5px;
  min-width: 150px;
  padding-top: 0;
  padding-right: 5px;
  padding-left: 0; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.pt-timepicker {
  display: inline-block;
  padding: 0 10px;
  white-space: nowrap; }
  .pt-timepicker .pt-timepicker-arrow-row {
    padding: 0 1px; }
  .pt-timepicker .pt-timepicker-arrow-button {
    color: #5c7080;
    width: 33px;
    padding: 4px 0;
    text-align: center; }
    .pt-timepicker .pt-timepicker-arrow-button:hover {
      color: #182026; }
    .pt-dark .pt-timepicker .pt-timepicker-arrow-button {
      color: #bfccd6; }
      .pt-dark .pt-timepicker .pt-timepicker-arrow-button:hover {
        color: #f5f8fa; }
    .pt-timepicker .pt-timepicker-arrow-button + .pt-timepicker-arrow-button {
      margin-left: 11px; }
    .pt-timepicker .pt-timepicker-arrow-button:hover {
      cursor: pointer; }
  .pt-timepicker .pt-timepicker-input-row {
    display: inline-block;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    background: #ffffff;
    height: 30px;
    padding: 0 1px;
    line-height: 28px; }
  .pt-timepicker .pt-timepicker-divider-text {
    display: inline-block;
    width: 11px;
    text-align: center;
    color: #5c7080;
    font-size: 16px; }
  .pt-timepicker .pt-timepicker-input {
    outline: 0;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0);
    background: transparent;
    width: 33px;
    height: 28px;
    padding: 0;
    text-align: center;
    color: #182026;
    transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .pt-timepicker .pt-timepicker-input:focus {
      box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3); }
  .pt-timepicker.pt-disabled .pt-timepicker-input-row {
    box-shadow: none;
    background: rgba(206, 217, 224, 0.5);
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5);
    resize: none; }
  .pt-timepicker.pt-disabled .pt-timepicker-input,
  .pt-timepicker.pt-disabled .pt-timepicker-divider-text {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }
  .pt-timepicker.pt-disabled .pt-timepicker-arrow-button,
  .pt-timepicker.pt-disabled .pt-timepicker-arrow-button:hover {
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.5); }

.pt-dark .pt-timepicker .pt-timepicker-input-row {
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3); }

.pt-dark .pt-timepicker .pt-timepicker-divider-text {
  color: #bfccd6; }

.pt-dark .pt-timepicker .pt-timepicker-input {
  color: #f5f8fa; }

/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
/*
Color aliases

These variables are semantic aliases of our [colors](#colors). They are used throughout Blueprint
itself to ensure consistent color usage across components.

<table class=pt-table>
  <thead>
    <tr>
      <th></th>
      <th>Variable</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><div class="docs-color-bubble alias-intent-primary"></div></td>
      <td><code>$pt-intent-primary</code></td>
      <td>Primary intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-success"></div></td>
      <td><code>$pt-intent-success</code></td>
      <td>Success intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-warning"></div></td>
      <td><code>$pt-intent-warning</code></td>
      <td>Warning intent color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-intent-danger"></div></td>
      <td><code>$pt-intent-danger</code></td>
      <td>Danger intent color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-app-background-color"></div></td>
      <td><code>$pt-app-background-color</code></td>
      <td>Application background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-app-background-color"></div></td>
      <td><code>$pt-dark-app-background-color</code></td>
      <td>Dark theme application background color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-text-color"></div></td>
      <td><code>$pt-text-color</code></td>
      <td>Default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-muted"></div></td>
      <td><code>$pt-text-color-muted</code></td>
      <td>Muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-color-disabled"></div></td>
      <td><code>$pt-text-color-disabled</code></td>
      <td>Disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-heading-color"></div></td>
      <td><code>$pt-heading-color</code></td>
      <td>Text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-link-color"></div></td>
      <td><code>$pt-link-color</code></td>
      <td>Text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color"></div></td>
      <td><code>$pt-dark-text-color</code></td>
      <td>Dark theme default text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-muted"></div></td>
      <td><code>$pt-dark-text-color-muted</code></td>
      <td>Dark theme muted text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-text-color-disabled"></div></td>
      <td><code>$pt-dark-text-color-disabled</code></td>
      <td>Dark theme disabled text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-heading-color"></div></td>
      <td><code>$pt-dark-heading-color</code></td>
      <td>Dark theme text color for headers</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-link-color"></div></td>
      <td><code>$pt-dark-link-color</code></td>
      <td>Dark theme text color for links</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-text-selection-color"></div></td>
      <td><code>$pt-text-selection-color</code></td>
      <td>Text selection color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-icon-color"></div></td>
      <td><code>$pt-icon-color</code></td>
      <td>Default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-hover"></div></td>
      <td><code>$pt-icon-color-hover</code></td>
      <td>Hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-disabled"></div></td>
      <td><code>$pt-icon-color-disabled</code></td>
      <td>Disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-icon-color-selected"></div></td>
      <td><code>$pt-icon-color-selected</code></td>
      <td>Selected icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color"></div></td>
      <td><code>$pt-dark-icon-color</code></td>
      <td>Dark theme default icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-hover"></div></td>
      <td><code>$pt-dark-icon-color-hover</code></td>
      <td>Dark theme hovered icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-disabled"></div></td>
      <td><code>$pt-dark-icon-color-disabled</code></td>
      <td>Dark theme disabled icon color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-icon-color-selected"></div></td>
      <td><code>$pt-dark-icon-color-selected</code></td>
      <td>Dark theme selected icon color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-divider-black"></div></td>
      <td><code>$pt-divider-black</code></td>
      <td>Black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-black"></div></td>
      <td><code>$pt-dark-divider-black</code></td>
      <td>Dark theme black divider color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-divider-white"></div></td>
      <td><code>$pt-dark-divider-white</code></td>
      <td>Dark theme white divider color</td>
    </tr>

    <tr>
      <td><div class="docs-color-bubble alias-code-text-color"></div></td>
      <td><code>$pt-code-text-color</code></td>
      <td>Code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-code-background-color"></div></td>
      <td><code>$pt-code-background-color</code></td>
      <td>Code background color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-text-color"></div></td>
      <td><code>$pt-dark-code-text-color</code></td>
      <td>Dark theme code text color</td>
    </tr>
    <tr>
      <td><div class="docs-color-bubble alias-dark-code-background-color"></div></td>
      <td><code>$pt-dark-code-background-color</code></td>
      <td>Dark theme code background color</td>
    </tr>
  </tbody>
</table>

Styleguide variables.colors
*/
.pt-datetimepicker {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 10px;
  text-align: center; }
  .pt-dark .pt-datetimepicker {
    background: #30404d; }
    .pt-dark .pt-datetimepicker .pt-datepicker {
      border-bottom: 1px solid rgba(16, 22, 26, 0.4); }
  .pt-datetimepicker .pt-datepicker {
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    padding: 0 0 10px; }
  .pt-datetimepicker .pt-timepicker {
    padding: 10px 0 0; }

.pt-dateinput-popover {
  padding: 0; }
